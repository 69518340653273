import IFeature from '@common/Feature.interface'
import Props from '@common/Props.interface'
import PropsWithSize from '@common/PropsWithSize.interface'
import { Size } from '@components/shared'
import Icon from '@icons/Icon'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'

enum Variant {
  IGNORE_THEME = 'IGNORE_THEME',
}

export interface FeaturesProps extends Props, PropsWithSize {
  features: IFeature[]
  variant?: Variant
}

const SIZE_MAPS: Record<Size, string> = {
  [Size.SMALLER]: 'w-8',
  [Size.SMALL]: 'w-12',
  [Size.MEDIUM]: 'w-18',
  [Size.LARGE]: 'w-24',
  [Size.LARGER]: 'w-28',
} as const

const Features = ({ features, theme, variant, size = Size.SMALLER }: FeaturesProps) => {
  const sizeClassName = size ? SIZE_MAPS[size] : null
  // eslint-disable-next-line no-nested-ternary
  const iconTheme = isNullOrUndefined(variant)
    ? theme
    : variant === Variant.IGNORE_THEME
    ? undefined
    : theme
  return (
    <div className="flex flex-col gap-5">
      {features.map((feature) => {
        const title = feature.title ? <div className="font-bold">{feature.title}</div> : null
        const description = feature.description ? <div>{feature.description}</div> : null
        return (
          <div className="flex gap-4" key={feature.id}>
            <div className={classNames('flex flex-none items-start justify-center', sizeClassName)}>
              <Icon icon={feature.icon} theme={iconTheme} size={size} variant={feature.variant} />
            </div>
            <div className="flex flex-col">
              {title}
              {description}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Features
